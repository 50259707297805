import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-pluralrules/polyfill";
import { Container } from "react-bootstrap";

import "./main.scss";

const Layout = ({ top, center, messages }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          logo
          title
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `);
  const { defaultLangKey } = data.site.siteMetadata.languages;

  return (
    <IntlProvider locale={defaultLangKey} messages={messages}>
      <main>
        {top && (
          <div className="layout--top">
            <Container className="p-3">{top}</Container>
          </div>
        )}
        {center && (
          <div className="bg-light layout--center">
            <Container className="p-3">{center}</Container>
          </div>
        )}
      </main>
    </IntlProvider>
  );
};

Layout.propTypes = {
  center: PropTypes.node.isRequired,
  top: PropTypes.node.isRequired,
  messages: PropTypes.object,
  currentForm: PropTypes.string
};

export default Layout;
